import { Box, Button, Divider, Typography } from "@mui/material";
import AppBarSimple from "../components/AppBarSimple";
import Footer from "../components/Footer";
import Pricing from "../components/Pricing";
import { useNavigate } from 'react-router-dom';
import { useEffect } from "react";
import { useAuth } from "../context/AuthContext";
import { Download } from "@mui/icons-material";
import { logEventAnalytics } from "../lib/firebase/analytics";
import ReactPlayer from "react-player";

const LandingPage = () =>{
    const navigate = useNavigate();
    const { user } = useAuth();
    const downloadUrl = "https://r2-worker-2.gamevisionpro.workers.dev/Setup.exe?action=get";

    useEffect(()=>{
        if(window.electron){
            navigate('/criar-conta');
        }
        if(user){
            navigate('/dashboard');
        }
    },[user,navigate])

    const handleDownload = () => {
            logEventAnalytics("download",
                {
                    url: downloadUrl
                }
            );
            window.location.href = downloadUrl;
        }

    return(
        <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh', }}>
			<AppBarSimple />   

            <Box
                sx={{
                    position: 'relative',
                    backgroundImage: 'url(/images/game-vision-pro.jpg)',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    width: '100%',
                    height: {xs: '75vh', md: '85vh'},
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'flex-start',
                    textAlign: 'left',
                    color: '#fff',
                }}
            >
                
                <Box
                    sx={{
                        position: 'absolute',
                        width: '100%',
                        height: '100%',
                        background: 'linear-gradient(to right, rgba(0, 0, 0, .9) 0%, rgba(0, 0, 0, 0) 100%)',
                        zIndex: 1,
                    }}
                />

                <Box
                    sx={{
                        position: 'relative',
                        zIndex: 2,
                        maxWidth: '700px',                
                        px: {xs: 2, sm: 3, md: 6},
                    }}
                >
                    <Typography variant="h3" component="h1" gutterBottom fontWeight={700} >
                        Game Vision PRO
                    </Typography>
                    <Typography variant="h6" component="h2" gutterBottom>
                        <Box display="flex" alignItems="center" gap={1}>                            
                            Análise Esportiva e Edição de Vídeo
                        </Box>
                    </Typography>
                    <Typography variant="h5" component="p" sx={{mt:2,mb:6}} fontWeight={300}>
                        Faça análises, produza gráficos e edite vídeos com o Game Vision PRO.
                    </Typography>
                    
                    <Button 
                        variant="contained" 
                        size="large" 
                        sx={{mr:2, my:1}} 
                        /*onClick={()=>{navigate('/download')}}*/
                        onClick={handleDownload}
                        startIcon={<Download/>}
                    >
                        Download Grátis
                    </Button>
                    
                    <Button variant="outlined" size="large" sx={{my:1}} color="inherit" onClick={()=>{navigate('/tutorial')}}>
                        Ver Tutoriais
                    </Button>
                
                </Box>
            </Box>

            <Divider />

            <Box sx={{p:4, mb:2, display:'flex', alignItems:'center', flexDirection:'column'}}>
                <Typography variant="h3" component="h2" gutterBottom fontWeight={700} >
                    Conheça o Game Vision PRO
                </Typography>

                <Box sx={{mt:4, mb:4, height:'50vh', width: '100%'}}>
                    <ReactPlayer
                        url="https://vimeo.com/1052600620"
                        width="100%"
                        height="100%"
                        controls={true}
                    />
                </Box>
                    
                <Button 
                    variant="contained" 
                    size="large" 
                    sx={{mr:2, my:1}} 
                    onClick={handleDownload}
                    startIcon={<Download/>}
                >
                    Download Grátis
                </Button>

            </Box>

            <Divider />

            <Box sx={{p:4, mb:2, display:'flex', alignItems:'center', flexDirection:'column'}}>
                <Typography variant="h3" component="h2" gutterBottom fontWeight={700} >
                    Edição de Vídeo
                </Typography>
                
                <Typography variant="h5" component="p" sx={{mt:2,mb:6}} fontWeight={300}>
                    Faça ilustrações em segundos sem sair da tela de análise.
                </Typography>

                <img style={{maxWidth: '100%'}} src="/images/game-vision-pro-edicao-video.png" alt="Game Vision PRO - Edição de Vídeo"/>

            </Box>



            <Divider />

            <Box sx={{p:4, mb:2, display:'flex', alignItems:'center', flexDirection:'column'}}>
                <Typography variant="h3" component="h2" gutterBottom fontWeight={700} >
                    Gráficos Automáticos
                </Typography>
                
                <Typography variant="h5" component="p" sx={{mt:2,mb:6}} fontWeight={300}>
                    Registre as ações e tenha gráficos em tempo real sem esforço.
                </Typography>

                <img style={{maxWidth: '100%'}} src="/images/game-vision-pro-graficos-automaticos.png" alt="Game Vision PRO - Gráficos Automáticos" />

            </Box>

            <Divider />

            <Box sx={{p:4, mb:2, display:'flex', alignItems:'center', flexDirection:'column'}}>
                <Typography variant="h3" component="h2" gutterBottom fontWeight={700} >
                    Crie Seu Banco de Dados
                </Typography>
                
                <Typography variant="h5" component="p" sx={{mt:2,mb:6}} fontWeight={300}>
                    Organize suas análises e facilite suas consultas criando um banco de dados próprio.
                </Typography>

                <img style={{maxWidth: '100%'}} src="/images/game-vision-pro-banco-dados.png" alt="Game Vision PRO - Banco de Dados"/>

            </Box>

            <Divider />
            
            <Pricing />

            <Divider />

            <Footer />

        </Box>
    );
}

export default LandingPage;