import { Check } from "@mui/icons-material";
import { Box, Button, Grid, Paper, Typography } from "@mui/material";
import { useNavigate } from 'react-router-dom';
import { useAuth } from "../context/AuthContext";

const Pricing = () => {
    const navigate = useNavigate();
    const {user, permission} = useAuth();

    return(
        <Box sx={{p:4, mb:2, display:'flex', alignItems:'center', flexDirection:'column'}}>
            <Typography variant="h3" component="h2" gutterBottom fontWeight={700} >
                Planos Disponíveis
            </Typography>
            
            <Typography variant="h5" component="p" sx={{mt:2,mb:6}} fontWeight={300}>
                Escolha o plano ideal para você.
            </Typography>

            <Grid container spacing={3}>
                <Grid item xs={12} sm={6} sx={{display:'flex', justifyContent:{xs:'center',sm:'flex-end'}}}>
                    <Paper sx={{p:4, maxWidth:"450px",display:'flex',flexDirection:'column',alignItems:'center'}}>
                        <Typography variant="h5" fontWeight={700}>BÁSICO</Typography>                            
                        <Box sx={{display:'flex', my:2, alignItems:'center', justifyContent:'center'}}>
                            <Typography variant="body1">R$</Typography>
                            <Typography variant="h1">0</Typography>
                            <Typography variant="body1">/mês</Typography>
                        </Box>
                        <Box>
                            <Typography color="text.secondary" component="div" gutterBottom>
                                <Box display="flex" alignItems="center" gap={1}>
                                    <Check color="primary" />
                                    <span>Painéis Básicos.</span>
                                </Box>
                            </Typography>
                            <Typography color="text.secondary" component="div" gutterBottom>
                                <Box display="flex" alignItems="center" gap={1}>
                                    <Check color="primary" />
                                    <span>Funções Limitadas.</span>
                                </Box>
                            </Typography>
                            <Typography color="text.secondary" component="div" gutterBottom>
                                <Box display="flex" alignItems="center" gap={1}>
                                    <Check color="primary" />
                                    <span>Exportação com Marca d`água.</span>
                                </Box>
                            </Typography>
                        </Box>
                        {!window.electron && (
                            <Button sx={{mt:4}} variant="outlined" onClick={()=>{navigate('/download')}}>
                                Download Grátis
                            </Button>
                        )}
                        
                    </Paper>
                </Grid>
                
                <Grid item xs={12} sm={6} sx={{display:'flex', justifyContent:{xs:'center',sm:'flex-start'}}}>
                    <Paper sx={{p:4, maxWidth:"450px",display:'flex',flexDirection:'column',alignItems:'center'}}>
                        <Typography variant="h5" fontWeight={700}>PROFISSIONAL</Typography>                            
                        <Box sx={{display:'flex', my:2, alignItems:'center', justifyContent:'center'}}>
                            <Typography variant="body1">R$</Typography>
                            <Typography variant="h1">29</Typography>
                            <Typography variant="body1">/mês</Typography>
                        </Box>
                        <Box>
                            <Typography color="text.secondary" component="div" gutterBottom>
                                <Box display="flex" alignItems="center" gap={1}>
                                    <Check color="primary" />
                                    <span>Painéis Avançados.</span>
                                </Box>
                            </Typography>
                            <Typography color="text.secondary" component="div" gutterBottom>
                                <Box display="flex" alignItems="center" gap={1}>
                                    <Check color="primary" />
                                    <span>Todas as Funções Liberadas.</span>
                                </Box>
                            </Typography>
                            <Typography color="text.secondary" component="div" gutterBottom>
                                <Box display="flex" alignItems="center" gap={1}>
                                    <Check color="primary" />
                                    <span>Exportação sem Marca d`água.</span>
                                </Box>
                            </Typography>
                        </Box>
                        <Button sx={{mt:4}} variant="outlined" onClick={()=>{navigate('/assinar')}}>
                            Assinar Plano
                        </Button>
                    </Paper>
                </Grid>

            </Grid>

        </Box>
    );
}

export default Pricing;