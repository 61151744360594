import { Box, Button, Dialog, DialogContent, Grid, Typography } from "@mui/material";
import { useState } from "react";
import ReactPlayer from "react-player";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";

const Onboarding = ({open,handleClose}) => {
    
    const [isPlaying, setIsPlaying] = useState(false);
    const [isReady, setIsReady] = useState(false);

    return(
        <Dialog 
            open={open} 
            onClose={handleClose}
            fullWidth
            maxWidth="md"
        >
            <DialogContent sx={{display:'flex', flexDirection:'column', alignItems:'center'}}>
                <Typography variant="h4" fontWeight={700} sx={{mb:2}}>
                    Bem-vindo(a) ao Game Vision!
                </Typography>

                <Box sx={{ position: "relative", width: "100%", height: "60vh" }}>
                    
                    <ReactPlayer
                        url="https://vimeo.com/1052600620"
                        width="100%"
                        height="100%"
                        controls={true}
                        playing={isPlaying}
                        onPlay={() => setIsPlaying(true)}
                        onPause={() => setIsPlaying(false)}
                        onReady={() => setIsReady(true)}
                    />
                    
                    {!isPlaying && isReady && (
                        <Button
                            onClick={() => setIsPlaying(true)}
                            sx={{
                                position: "absolute",
                                top: "50%",
                                left: "50%",
                                transform: "translate(-50%, -50%)",
                                backgroundColor: "rgba(0, 0, 0, 0.7)",
                                color: "white",
                                borderRadius: "50%",
                                width: 80,
                                height: 80,
                                minWidth: "auto",
                                "&:hover": {
                                    backgroundColor: "rgba(0, 0, 0, 0.9)"
                                }
                            }}
                        >
                            <PlayArrowIcon sx={{ fontSize: 50 }} />
                        </Button>
                    )}
                </Box>

                <Grid container spacing={4} sx={{mt:0, mb:1}}>
                    <Grid item xs={4}>
                        <Button 
                            color="inherit" 
                            variant="outlined" 
                            fullWidth
                            onClick={()=>{
                                window.location.href = '/tutorial/edicao-estatica';
                            }}
                        >
                            Aprenda a fazer <br/> Edições de Vídeo
                        </Button>
                    </Grid>
                    
                    <Grid item xs={4}>
                        <Button 
                            color="inherit" 
                            variant="outlined" 
                            fullWidth
                            onClick={()=>{
                                window.location.href = '/tutorial/analise-basica'
                            }}
                        >
                            Aprenda a fazer <br/> Análises e Gráficos
                        </Button>
                    </Grid>

                    <Grid item xs={4}>
                        <Button 
                            color="inherit" 
                            variant="outlined" 
                            fullWidth
                            onClick={handleClose}
                        >
                            Estou Pronto(a) <br /> para Começar!
                        </Button>
                    </Grid>
                        
                </Grid>
                
            </DialogContent>
        </Dialog>
    );
}

export default Onboarding;