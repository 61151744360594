export async function sendSupportEmail(userEmail,message){
    const emailData = {
        recipient: "categoriacanal@gmail.com",
        subject: `Suporte Game Vision (${userEmail})`,
        htmlBody: `
            <p>Usuário: ${userEmail}</p>
            <p>Mensagem: ${message}</p>
        `,
    }
    try {
        await fetch('https://w7gylv72cmeojzhzbfnt3mo3zq0zavww.lambda-url.us-east-1.on.aws/', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(emailData),
        });
    } catch (error) {
      console.log(error);
    }
  }